"use strict";

var App = {
  init: function init() {
   /**********/
      $(function () {

            // Slideshow 1
            $("#slider1").responsiveSlides({
              maxwidth: 800,
              speed: 800,
              auto: true,
              pager: false,
              nav: true,
              timeout: 5000,
              namespace: "callbacks"
              
            });

          });
  /**********/  
  }
};

module.exports = App;