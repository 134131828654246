"use strict";

var App = {
  init: function init() {
   /**********/

		$(function() {
				    $('.datepicker').datepicker({
				    	  firstDay: 1,
						  closeText: 'Fermer',
						  prevText: 'Précédent',
						  nextText: 'Suivant',
						  currentText: 'Aujourd\'hui',
						  monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
						  monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
						  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
						  dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
						  dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
						  weekHeader: 'Sem.',
						  dateFormat: 'dd/mm/yy',
						  changeMonth: true,
				          changeYear: true,
				          showButtonPanel: true,
				          yearRange: "-100:+0"
				    })
				  });
	/**********/	
  }
};

module.exports = App;