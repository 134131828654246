"use strict";

var App = {
  init: function init() {
   /**********/

		$(window).bind("load", function() {
			       
			    
			            var image = 'img/map-marker.png';
			            var mapOptions = {
			                zoom: 8,
					        zoomControl : true,
					        scrollwheel: false,
					        navigationControl: false,
					        mapTypeControl: true,
					        draggable: true,
					        zoomControlOpt: {
					          style : 'SMALL',
					          position: 'TOP_LEFT'
					        },
			        		panControl : false,
			                center: new google.maps.LatLng(45.5456637 , -0.8918142),
			                mapTypeId: google.maps.MapTypeId.ROADMAP
			            }
			            var map = new google.maps.Map(document.getElementById('map'), mapOptions);
			            var locations = [
			            	['Camping 17 caravaning<br><strong>Fleurs des Champs</strong><br/>Le Coudinier<br>17120 Arces-sur-Gironde', 45.5456637 , -0.8918142 ]
			            	
			            ]
			            
			            var noPoi = [
					        {
					            featureType: "poi",
					            stylers: [
					              { visibility: "off" }
					            ]   
					          }
					        ];
					        map.setOptions({styles: noPoi});

					    var infowindow = new google.maps.InfoWindow();

					    var marker, i;

					    for (i = 0; i < locations.length; i++) {  
					      marker = new google.maps.Marker({
					        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
					        map: map,
					        icon: image
					      });

					      google.maps.event.addListener(marker, 'click', (function(marker, i) {
					        return function() {
					          infowindow.setContent(locations[i][0]);
					          infowindow.open(map, marker);
					        }
					      })(marker, i));
					    }



			       
			      });
	/**********/	
  }
};

module.exports = App;