"use strict";

var App = {
  init: function init() {
   /**********/

      $(function() {
        $('a[href*=#]:not([href=#])').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top
              }, 1000);
              return false;
            }
          }
        });
      });

      $(function() {
           var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/")+1);
           $("#main-nav li a").each(function(){
                if($(this).attr("href") == pgurl || $(this).attr("href") == '' )
                $(this).addClass("active");
           })
      });

  /**********/  
  }
};

module.exports = App;