"use strict";

var App = {
  init: function init() {
   /**********/
		$(document).ready(function() {
			$('.fancybox').fancybox({
				autoSize: true,
				width: 1600,
				autoWidth: true,
				minWidth: 420,
				maxWidth: 1600,
				height: 800,
				autoHeight: true,
				minHeight: 400,
				maxHeight: 800
				
			});

		});
	/**********/	
  }
};

module.exports = App;