"use strict";

var App = {
  init: function init() {
   /**********/
		$(document).ready(function() {
			$(".landing").vegas({
			    delay: 8000,
			    slides: [
			        { src: "img/slide-01.jpg" },
			        { src: "img/slide-02.jpg" },
			        { src: "img/slide-03.jpg" },
			        { src: "img/slide-04.jpg" },
			        { src: "img/slide-05.jpg" }
			    ]
			});

		});
	/**********/	
  }
};

module.exports = App;