"use strict";

var App = {
  init: function init() {
   /**********/
			jQuery(document).ready(function($){
			    
			    var mov = $('#mov');
			 
			    
			    var fixedLimit = mov.offset().top - parseFloat(mov.css('marginTop').replace(/auto/,0));
			 
			    
			    $(window).trigger('scroll');
			 
			    $(window).scroll(function(event){
			        
			        var windowScroll = $(window).scrollTop();
			 
			        
			        if( windowScroll >= fixedLimit ){
			            mov.addClass('fixed');
			        } else {
			            mov.removeClass('fixed');
			        }
			    });
			});
	/**********/	
  }
};

module.exports = App;